import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const PopupContainer = styled.div<{ isClosing: boolean }>`
    width: 100%;
    height: 200vh;
    @keyframes swipe-in {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(0);
        }
    }
    @keyframes swipe-out {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(100%);
        }
    }
    animation: ${({ isClosing }) =>
        isClosing ? 'swipe-out 0.3s' : 'swipe-in 0.3s'};
    position: absolute;
    right: 0;
    z-index: 2001;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #111111;
    h1 {
        margin-top: 42px;
        color: #fff;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        line-height: 34px; /* 141.667% */
        letter-spacing: -0.6px;
        cursor: pointer;
        &:hover {
            color: #07b0c7;
        }
    }
`;

const Cancle = styled.h2`
    margin-left: 0;
    text-align: end;
    cursor: pointer;
    &:hover {
        color: #07b0c7;
    }
`;

function HamburgerPopup({
    setHamburger,
}: {
    setHamburger: Dispatch<SetStateAction<boolean>>;
}) {
    const [isClosing, setIsClosing] = useState(false);
    const navigate = useNavigate();

    const handleHamburger = () => {
        if (!isClosing)
            setTimeout(() => {
                setHamburger(false);
            }, 100);
        setIsClosing(true);
    };

    const handleHeader = (e: any) => {
        if (e.target.id === 'event') alert('추후 추가될 서비스입니다.');
        else {
            setHamburger(false);
            navigate(`/${e.target.id}`);
        }
    };
    return (
        <PopupContainer isClosing={isClosing}>
            <Cancle onClick={handleHamburger}>X</Cancle>
            <h1 id="event" onClick={handleHeader}>
                이벤트
            </h1>
            <h1 id="notice" onClick={handleHeader}>
                공지사항
            </h1>
            <h1
                id="inquiry" // onClick={handleHeader}
                onClick={() => alert('축제 기간 시 오픈 예정입니다.')}
            >
                문의사항
            </h1>
        </PopupContainer>
    );
}

export default HamburgerPopup;
